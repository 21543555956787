import {TimelineMax} from 'gsap';

let tl = new TimelineMax({delay: 1});
		tl.fromTo($('.anim_line_1'), .2, {maxWidth:'0', opacity: 0}, {maxWidth:'180px', opacity: 1})
			.fromTo($('.anim_line_2'), .3, {maxWidth:'0', opacity: 0}, {maxWidth:'180px', opacity: 1})
			.fromTo($('.anim_line_3'), .5, {maxWidth:'0', opacity: 0}, {maxWidth:'550px', opacity: 1})
			.staggerFromTo($('.anim_circle'), .25, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, 0.25)

			.fromTo($('.person1__in'), .25, {scale:'0', opacity: 0}, {scale:'1', opacity: 1})
			.fromTo($('.p1_nametable_img'), .5, {x: -50, opacity: 0}, {x: 0, opacity: 1})
			.fromTo($('.p1_img'), .5, {y: 200, opacity: 0}, {y: 0, opacity: 1})

			.fromTo($('.person2__in'), .25, {scale:'0', opacity: 0}, {scale:'1', opacity: 1})
			.fromTo($('.p2_nametable_img'), .5, {y: 50, opacity: 0}, {y: 0, opacity: 1})
			.fromTo($('.p2_img'), .5, {y: 200, opacity: 0}, {y: 0, opacity: 1})
			.fromTo($('.p2_hand_img'), .1, {y: 10, opacity: 0}, {y: 0, opacity: 1})

			.staggerFromTo($('.cloud_anim_1'), .2, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, 0.2)
			.staggerFromTo($('.cloud_anim_2'), .2, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, 0.2)
			.staggerFromTo($('.cloud_2 .anim_stagger'), .2, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, .2)
			.staggerFromTo($('.cloud_3 .anim_stagger'), .2, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, .2)
			.staggerFromTo($('.cloud_anim_3'), .2, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, 0.2)

			.staggerFromTo($('.green_tooltip_1 .anim_green'), .2, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, .2)
			.staggerFromTo($('.green_tooltip_1_2 .anim_green_2'), .2, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, .2)
			.staggerFromTo($('.green_tooltip_3 .anim_green'), .2, {scale:'0', opacity: 0}, {scale:'1', opacity: 1}, .2);


function parallaxFigure() {
	var items = document.querySelector('.first-screen-home .bl_right'),
		windowWidth = $(window).width();

	var mouseX, mouseY;
	var traX, traY;

	var movementConstant = .010;

	if (windowWidth > 992 && items) {
		items.addEventListener('mousemove', moveElement);
	}

	function moveElement(e) {
		var tw = this.offsetWidth,
			th = this.offsetHeight,
			// figure_1 = this.querySelector('.p1_img'),
			// figure_2 = this.querySelector('.p2_img'),
			figure_3 = this.querySelector('.cloud_1'),
			figure_4 = this.querySelector('.cloud_2'),
			figure_5 = this.querySelector('.cloud_3'),
			figure_6 = this.querySelector('.cloud_4'),
			figure_7 = this.querySelector('.cloud_1_1'),
			figure_8 = this.querySelector('.green_tooltip_1'),
			figure_9 = this.querySelector('.green_tooltip_1_2'),
			figure_10 = this.querySelector('.green_tooltip_3'),
			offsetThis = offset(this),
			offsetX = offsetThis.left,
			offsetY = offsetThis.top;

		mouseX = e.pageX;
		mouseY = e.pageY;

		this.classList.add('hover');

		traX = (((mouseX - offsetX) - tw / 2) * -1) * movementConstant;
		traY = (((mouseY - offsetY) - th / 2) * -1) * movementConstant;

		// figure_1.style.transform = "translate(" + traX + "%," + traY + "%)";
		// figure_2.style.transform = "translate(" + traX * -1 + "%," + traY * -1 + "%)";
		figure_3.style.transform = "translate(" + traX * 1.3 + "%," + traY * 1.3 + "%)";
		figure_4.style.transform = "translate(" + traX + "%," + traY + "%)";
		figure_5.style.transform = "translate(" + traX * -1.3 + "%," + traY * -1.3 + "%)";
		figure_6.style.transform = "translate(" + traX * -1 + "%," + traY * -1 + "%)";
		figure_7.style.transform = "translate(" + traX * -1.3 + "%," + traY * -1.3 + "%)";
		figure_8.style.transform = "translate(" + traX * 1.3 + "%," + traY * 1.3 + "%)";
		figure_9.style.transform = "translate(" + traX * -1.1 + "%," + traY * -1.1 + "%)";
		figure_10.style.transform = "translate(" + traX * 1.3 + "%," + traY * 1.3 + "%)";

	}

}


function offset(el) {
	var rect = el.getBoundingClientRect(),
		scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	return {
		top: rect.top + scrollTop,
		left: rect.left + scrollLeft
	}
}


// function comeBlock(selectorBl, selectorAnimElement) {
//
// 	let scrolled = window.pageYOffset || document.documentElement.scrollTop;
// 	let item = document.querySelectorAll(selectorBl),
// 			blHeight = item[0].clientHeight,
// 			blOffsetTop = item[0].offsetTop,
// 			andBlock = blOffsetTop + blHeight,
// 			elemAnim = $(selectorAnimElement);
//
// 			let advanceIn = 200;
//
// 			if (scrolled + advanceIn > blOffsetTop) {
// 				elemAnim.addClass("animated");
// 				// animMethod();
// 			}
//
// 			// if (scrolled > andBlock) {
// 			// 	elemAnim.removeClass("animated");
// 			// }
//
// }


// function scroll() {
// 	window.onscroll = function () {

		// let tl2 = new TimelineMax();
		// comeBlock('.m-hours', '.hour-anim');
		// function animMethod (){
		// 	tl2.staggerFromTo($('.m-hours .hour-anim'), .25, {scale:'0'}, {scale:'1'}, 0.2).play();

		// }

// 	};
// }

// function linkAnim() {
// 	$('.bg_blue a[href*="#"]').click(function() {
// 		$('html, body').animate({
// 			scrollTop: $($.attr(this, 'href')).offset().top
// 		}, 800);
// 		return false;
// 	});
// }


function domReady() {
	parallaxFigure();
	// scroll();
	// linkAnim();

}
window.addEventListener('load', domReady);
